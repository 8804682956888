import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const VisualizationPage = () => {
  return (
    <Layout
      meta={{
        description:
          "Layer styling is essential for making your maps lively and informative. It turns your data into something that's both beautiful and understandable.",
        title: 'Visualization | Atlas',
        type: 'website'
      }}
      title={'Visualization | Atlas'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Visualize data with Atlas'}
            description={
              "Layer styling is essential for making your maps lively and informative. It turns your data into something that's both beautiful and understandable."
            }
            video="https://cdn.atlas.co/landing-pages/visualization-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>Make maps informative, straightforward, and attractive</h2>
            <p>
              Enhance your maps with intuitive design and styling tools.
              Transform complex data into clear, visually appealing maps.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/visualize-and-style-data.mp4'
            }
            icon={{ src: '/icons/style.svg', color: '#FFBF6E' }}
          >
            <h3>Flexible colorization</h3>
            <p>
              Easily upload geo files, spreadsheets, images, and links directly
              onto the map. Our platform supports all major formats, including
              .geoJSON, .KML, .shp, and over 30 others, ensuring seamless data
              integration.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/zoom-based-styling.mp4'}
            reverse
            icon={{ src: '/icons/distance.svg', color: '#CDE4E1' }}
          >
            <h3>Dynamic styles based on zoom</h3>
            <p>
              Transform your spatial data into insightful visualizations with
              our powerful styling tools.
            </p>
            <p>
              Customize colors, symbols, and labels to highlight key patterns
              and trends. Create dynamic, interactive maps that bring your data
              to life, making complex information easy to understand and share.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/3d-visualization.mp4'}
            icon={{
              src: '/icons/three-dimensional.svg',
              color: '#C2E5FF'
            }}
          >
            <h3>Immersive 3D visualization</h3>
            <p>
              Unlock deeper insights with our advanced spatial analysis tools.
            </p>
            <p>
              Conduct proximity searches, overlay analysis, and data aggregation
              to reveal hidden patterns and relationships. Easily perform
              buffer, intersection, and density analyses to support your
              decision-making processes.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default VisualizationPage
